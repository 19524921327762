import React from 'react';
import * as Sentry from "@sentry/react";

import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
// import './index.css';
import App from './App';
// import reportWebVitals from './reportWebVitals';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import { UserContextProvider } from './context/userContext';
import store, { persistor } from './redux/store';
import { BrowserRouter as Router } from 'react-router-dom';
import { QueryClientProvider, QueryClient } from '@tanstack/react-query';
import { PersistGate } from 'redux-persist/integration/react';

const queryClient = new QueryClient();

Sentry.init({
	dsn: process.env.REACT_APP_SENTRY_WEBVIEWS_DSN,
	environment: process.env.NODE_ENV,
	integrations: [
		// See docs for support of different versions of variation of react router
		// https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
		Sentry.browserTracingIntegration(),
		Sentry.replayIntegration(),
	],

	// Set tracesSampleRate to 1.0 to capture 100%
	// of transactions for tracing.
	profilesSampleRate: 0.1,
	tracesSampleRate: 0.1,

	// Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
	tracePropagationTargets: [/^\//, /^https:\/\/yourserver\.io\/api/],

	// Capture Replay for 10% of all sessions,
	// plus for 100% of sessions with an error
	// replaysSessionSampleRate: 0.1,
	// replaysOnErrorSampleRate: 1.0,
});

Sentry.setUser({ id: window.sessionStorage.getItem("data") ?? 'jWjeLoO8C3NG9fmFm1icAq1c33E2' });

const rootElement = document.getElementById('root');
const root = ReactDOM.createRoot(rootElement, {
	// Callback called when an error is thrown and not caught by an ErrorBoundary.
	onUncaughtError: Sentry.reactErrorHandler((error, errorInfo) => {
		console.warn('Uncaught error', error, errorInfo?.componentStack);
	}),
	// Callback called when React catches an error in an ErrorBoundary.
	onCaughtError: Sentry.reactErrorHandler(),
	// Callback called when React automatically recovers from errors.
	onRecoverableError: Sentry.reactErrorHandler(),
});
rootElement.setAttribute('notranslate', true);
root.render(
	<React.StrictMode>
		<QueryClientProvider client={queryClient}>
			<Provider store={store}>
				<PersistGate loading={<h2>Loading...</h2>} persistor={persistor}>
					<UserContextProvider>
						<Router>
							<App />
						</Router>
					</UserContextProvider>
				</PersistGate>
			</Provider>
		</QueryClientProvider>
	</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
