import { BlobServiceClient } from "@azure/storage-blob";
const { v4 } = require("uuid");

// const sasToken = "?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2029-08-03T13:46:13Z&st=2023-08-03T05:46:13Z&spr=https&sig=VRuV%2B%2BhplMIrG3qbGZn2eY1pWyWiTmTQen7Ar3eiGOk%3D";
// const sasToken = "?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2030-09-14T00:30:52Z&st=2023-09-12T16:30:52Z&spr=https,http&sig=P4eG%2B3V7REoWaS6%2F90LuaobNFxko1hQW0Q3iFw1ZBt4%3D"
const sasToken = "?sv=2022-11-02&ss=bfqt&srt=co&sp=rwlacupitfx&se=2030-07-16T15:18:49Z&st=2024-10-14T07:18:49Z&spr=https,http&sig=0D3sDuAMGhQvgljsBLv03kXW7cb3drzj91PHqG2%2Bb5g%3D";
const storageAccountName = "kcusersposts2";
export let progress = 0;

export const isStorageConfigured = () => {
  return !(!storageAccountName || !sasToken);
};

const createBlobInContainer = async (
  containerClient,
  file,
  cmp,
  userID,
  setPercentage,
) => {
  const today = new Date();
  const uidd = v4();
  const epoch = String(Math.floor(today / 1000));
  const blobClient = containerClient.getBlockBlobClient(
    `${String(cmp)}/${userID}/${String(
      today.toISOString().split("T")[0]
    )}/${String(uidd)}/${epoch.concat(String(file.name))}`
  );

  try {
    await blobClient.uploadData(file, {
      blockSize: 4 * 1024 * 1024,
      concurrency: 20,
      onProgress: (ev) => {
        let prg = (ev?.loadedBytes / file.size) * 100;
        setPercentage(prg);
      },
      blobHTTPHeaders: { blobContentType: file.type },
    });
    await blobClient.setMetadata({ UserName: "shubham" });
  } catch (err) {
    console.log("error occurred while uploading video on azure: ", err)
  }
  var url = blobClient.url.split("?")[0];
  return url;
};

export const uploadFileToBlob = async (
  file,
  cmp,
  userID,
  setPercentage,
  containerName
) => {
  try {
    if (!file) {
      return [];
    }
    const blobService = new BlobServiceClient(
      `https://${storageAccountName}.blob.core.windows.net/?${sasToken}`
    );

    const campaigns = String(cmp);
    const uid = String(userID);
    const containerClient = blobService.getContainerClient(containerName);
    const url = await createBlobInContainer(
      containerClient,
      file,
      campaigns,
      uid,
      setPercentage
    );
    return url;
  } catch (e) {
    console.log(e);
  }
};

export default uploadFileToBlob;
