
export const initialCategory = { name: "सारे प्रोडक्ट्स" };
export const CATEGORY = "category";
export const BRAND = "brand";
export const MANUFACTURER = "manufacturer"
export const getDetails = async (
    setProducts,    
    header,
    setIsLoading,
    manufacturer,
    setApiError,
    selectedHeader = initialCategory.name,
) => {
    setIsLoading(true);
    const body = {
        data: {
            manufacturer,
            env: "prod",
            uid: window.sessionStorage.getItem("data"),
        },
    };
    
    if (header === BRAND && selectedHeader !== initialCategory.name) {
        body.data.brand = selectedHeader;
    } else if (header === CATEGORY && selectedHeader !== initialCategory.name) {
        body.data.category = selectedHeader;
    }

    const options = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
    };
    try {
        const res = await fetch(
            `https://asia-south1-op-d2r.cloudfunctions.net/brandsPage`,
            options
        );
        if (res.status === 200) {
            const response = await res.json();
            let tempHeader;

            if (header === CATEGORY) {
                const categories = response?.result?.categories;
                tempHeader = JSON.parse(JSON.stringify(categories));
                tempHeader.unshift(initialCategory);
            } else {
                const brands = response?.result?.brands;
                tempHeader = JSON.parse(JSON.stringify(brands));
                tempHeader.unshift(initialCategory);
            }
            const products = response?.result?.products;
            setProducts(products, tempHeader, response?.result?.prices);
            setApiError("");
        } else {
            const response = await res.json();
        }
    } catch (e) {
        setApiError(e.message);
        setProducts([]);
        return false;
    } finally {
        setIsLoading(false);
    }
};

export const getProductDetails = async (setItem, id, manufacturer) => {
    const body = {
        data: {
            ...(manufacturer?{manufacturer: manufacturer}:null),
            env: "prod",
            productID: id,
            uid: window.sessionStorage.getItem("data"),
        },
    };
    const options = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
    };
    try {
        const res = await fetch(
            `https://asia-south1-op-d2r.cloudfunctions.net/brandsPage`,
            options
        );
        if (res.status === 200) {
            const response = await res.json();
            const products = response?.result;
            setItem(products[0])
        } else {
            const response = await res.json();
        }
    } catch (e) {
        return false;
    }
}


export const PINCODE_NAV_BRANDS = [
    "tooyumm",
	"colgate",
	"nabati",
]

export const MANUFACTURER_BRANDS = {
    TOO_YUMM: "tooyumm",
    COLGATE: "colgate",
    NABATI: "nabati",
    ZED_BLACK: "zedblack",
    GODREJ_EXPERT: "godrejexpert",
    NILONS: "nilons",
    ZOFF_FOODS: "zofffoods",
    GODESI: "godesi",
}

export const BRAND_IDS = {
    TOO_YUMM: "brand_tooyumm",
    COLGATE: "brand_colgate",
    NABATI: "brand_nabati",
    ZED_BLACK: "brand_zedblack",
    GODREJ_EXPERT: "brand_godrejexpert",
    NILONS : "brand_nilons",
}