import React, { useRef } from "react";

function RippleButton({
  className,
  rippleColor,
  title,
  onSubmit,
  isDisabled,
  trailingIcon,
  startingIcon,
  style = {},
  disabledButtonExtraStyle = ''
}) {
  const buttonRef = useRef(null);
  const timeoutIdRef = useRef(null);

  const onButtonMouseDown = (e) => {
    if (isDisabled) return;

    clearTimeout(timeoutIdRef.current);
    const ripple = e.target.querySelector(".ripple");
    if (!ripple) return;  // Check if ripple element exists before proceeding

    const size = buttonRef.current.offsetWidth;
    const pos = buttonRef.current.getBoundingClientRect();
    const x = e.clientX - pos.left - size / 2; // Corrected calculation to center the ripple
    const y = e.clientY - pos.top - size / 2;

    ripple.style.top = `${y}px`;
    ripple.style.left = `${x}px`;
    ripple.style.width = `${size * 2}px`;
    ripple.style.height = `${size * 2}px`;

    ripple.classList.remove("rippleActive");
    ripple.classList.remove("rippleStart");

    setTimeout(() => {
      ripple.classList.add("rippleStart");
      setTimeout(() => {
        ripple.classList.add("rippleActive");
      }, 0);
    }, 0);
  };

  const onButtonMouseUp = (e) => {
    if (isDisabled) return;

    const ripple = e.target.querySelector(".ripple");
    if (!ripple) return;  // Check if ripple element exists before proceeding

    clearTimeout(timeoutIdRef.current);
    timeoutIdRef.current = setTimeout(() => {
      ripple.classList.remove("rippleStart");
      ripple.classList.remove("rippleActive");
    }, 500);
  };

  const onClick = () => {
    if (isDisabled) return;

    onSubmit?.();
  };

  return (
    <div
      onClick={onClick}
      ref={buttonRef}
      onMouseDown={onButtonMouseDown}
      onMouseUp={onButtonMouseUp}
      style={style}
      className={`${className} relative overflow-hidden ${isDisabled && `opacity-50 ${disabledButtonExtraStyle}`
        }`}
    >
      {startingIcon && !React.isValidElement(startingIcon) && <img
        src={startingIcon}
        alt="Button Image"
        style={{
          alignSelf: "center",
          width: '20px',
          height: '20px'
        }}
      />}
      {startingIcon && React.isValidElement(startingIcon) && startingIcon}
      &nbsp;{title}&nbsp;
      {trailingIcon && !React.isValidElement(trailingIcon) && <img
        src={trailingIcon}
        alt="Button Image"
        style={{
          alignSelf: "center",
          width: '20px',
          height: '20px'
        }}
      />}
      {trailingIcon && React.isValidElement(trailingIcon) && trailingIcon}
      <div className={`bg-[${rippleColor}] ripple`} />
    </div>
  );
}

export default RippleButton;
